import React, { useState, useContext } from "react";
import { forgotPassword } from "../api/auth";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const initialValues = {
    email: "",
  };

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      await forgotPassword(values);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <h2 className="text-3xl text-center mb-10">Forgot Password</h2>
            <div>
              Please enter the email address associated with your account. We'll
              send you instructions on how to reset your password.
            </div>
            <div className="my-8">
              <label
                htmlFor="username"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Email
              </label>
              <Field
                type="text"
                id="email"
                name="email"
                className="input input-bordered"
              />
              <ErrorMessage name="email" className="error" component="div" />
            </div>

            {/* {errorMessage && <p className="text-red-500 text-sm mb-4">{errorMessage}</p>} */}
            <div className="flex justify-between items-center">
              <Link to="/login">Login</Link>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isLoading} // Disable button while loading
              >
                {isLoading ? "Loading..." : "Submit"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ForgotPassword;

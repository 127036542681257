import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import Topbar from "./Topbar";
import { AuthContext } from "../context/AuthContext";

const AuthLayout = ({ children }) => {
  const { authToken, user } = useContext(AuthContext);
  if (authToken) {
    if(user.role == 'admin'){
        return <Navigate to="/communities" />;
    }else{
        return <Navigate to={`${user.slug}/members`} />;
    }
  }
  return (
    <div className="flex flex-col justify-between min-h-screen">
      <Topbar />
      <div className="flex h-full items-center justify-center">
        <div className="w-full max-w-md px-4">
          <Outlet />
        </div>
      </div>
      <div>&nbsp;</div>
    </div>
  );
};

export default AuthLayout;

import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { animateScroll } from "react-scroll";
import { Link, useLocation } from "react-router-dom";
import { get } from "../../api/messages";
import ChatItem from "../../components/ChatItem";
import NoItems from "../../components/NoItems";

const Messages = () => {
  const { community_slug, member_id, receiver_id, type } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [users, setUsers] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const chatRef = useRef(null);
  const [loadingNextPage, setLoadingNextPage] = useState(true);

  const fetchData = async () => {
    console.log("laoding");
    
    if (isLoading) return;
    if (page > 1) {
      setLoadingNextPage(true);
    } else {
      setIsLoading(true);
    }
    try {
      const currentScrollHeight = chatRef.current.scrollHeight;
      const response = await get({
        community_slug,
        member_id,
        receiver_id,
        type,
        page,
      });
      setUsers(response.data.users);
      setData((prevMessages) => [
        ...response.data.chats.data.reverse(),
        ...prevMessages,
      ]);
      setHasMore(response.data.chats.hasMorePages !== null);

      // Scroll to bottom on initial load
      if (page === 1) {
        setTimeout(() => {
          scrollToBottom();
        }, 1000);
      } else {
        setTimeout(() => {
          const newScrollHeight = chatRef.current.scrollHeight;
          const scrollDelta = newScrollHeight - currentScrollHeight;
          animateScroll.scrollMore(scrollDelta, {
            containerId: "chatContainer",
            duration: 300,
            delay: 0,
            smooth: "easeInOutQuart",
          });
        }, 10);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setLoadingNextPage(false);
      }, 500);
    }
  };

  const handleScroll = useCallback(() => {
    if (chatRef.current.scrollTop === 0 && hasMore && !isLoading) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [hasMore, isLoading]);

  useEffect(() => {
    const chatElement = chatRef.current;
    if (chatElement) {
      chatElement.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (chatElement) {
        chatElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  const scrollToBottom = () => {
    if (chatRef.current) {
      animateScroll.scrollToBottom({
        containerId: "chatContainer",
        duration: 300,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    setData([])
    setPage(1)
  }, [location]);

  return (
    <>
      {/* Message list */}
      <div className="my-10 border border-gray-300 p-3 rounded-lg relative">
        <div className="flex justify-between items-center border-b border-gray-300 mb-3">
          {users && (
            <div className="text-2xl p-4">
              {users[0]} & {users[1]}
            </div>
          )}
          <div>
            <div className="join mr-4">
              <Link
                to={`/${community_slug}/members/${member_id}/message/${receiver_id}/community`}
                className={`btn btn-primary join-item ${
                  type != "community" ? "btn-outline" : ""
                }`}
              >
                Community Chat
              </Link>

              <Link
                to={`/${community_slug}/members/${member_id}/message/${receiver_id}/group`}
                className={`btn btn-primary join-item ${
                  type != "group" ? "btn-outline" : ""
                }`}
              >
                Group Chat
              </Link>
            </div>
            <button
              className="btn btn-link no-underline hover:no-underline"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
          </div>
        </div>
        {isLoading === true && data?.length === 0 && (
          <>
            <ChatItem key={1} loading={true} />
            <ChatItem key={2} loading={true} />
          </>
        )}
        {isLoading === false &&
          (typeof data === "undefined" || data?.length === 0) && <NoItems />}
        <div
          className="h-[70vh] overflow-y-auto"
          id="chatContainer"
          ref={chatRef}
        >
          {isLoading === false &&
            typeof data !== "undefined" &&
            data?.map((item) => (
              <ChatItem
                key={item.id}
                item={item}
                community_slug={community_slug}
                member_id={member_id}
              />
            ))}
        </div>
        {loadingNextPage === true && (
          <div className="absolute inset-0 bg-black/20 z-50 flex justify-center items-center">
            Please Wait...
          </div>
        )}
      </div>
    </>
  );
};

export default Messages;

export function date(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed
  const day = String(date.getDate()).padStart(2, "0");
  return `${month}-${day}-${year}`;
}

export function time(dateString) {
  const date = new Date(dateString);
  const hour = date.getHours();
  const min = date.getMinutes();
  return `${hour}:${min}`;
}

export function isVideo(url) {
  const videoExtensions = ["mp4", "webm", "ogg"];
  const extension = url.split(".").pop();
  return videoExtensions.includes(extension);
}

import API from "./api";

export const list = async ({ community_slug, member_id }) => {
  let APIURL = `${community_slug}/messages?id=${member_id}`;
  return await API.get(APIURL);
};

export const get = async ({
  community_slug,
  member_id,
  receiver_id,
  type,
  page,
}) => {
  let APIURL = `${community_slug}/messages/${member_id}?receiver_id=${receiver_id}&page=${page}&chat_type=${type}`;
  return await API.get(APIURL);
};

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { index } from "../../api/requests";
import RequestItem from "../../components/RequestItem";
import NoItems from "../../components/NoItems";
import Pagination from "../../components/Pagination";

const Requests = () => {
  const { community_slug } = useParams();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async (page = 1) => {
    setIsLoading(true);
    try {
      const response = await index({ community_slug, page });
      setData(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {/* Requests list */}
      <div className="border border-gray-300 p-3 rounded-lg striped">
        <RequestItem heading={true} />
        {isLoading === true && (
          <>
            <RequestItem loading={true} />
            <RequestItem loading={true} />
          </>
        )}
        {isLoading === false &&
          (typeof data?.data === "undefined" || data?.data.length === 0) && (
            <NoItems />
          )}
        {isLoading === false &&
          typeof data?.data !== "undefined" &&
          data?.data.map((item) => (
            <RequestItem
              key={item.id}
              item={item}
              fetchData={fetchData}
              community_slug={community_slug}
            />
          ))}
      </div>
      {isLoading === false && typeof data?.data !== "undefined" && (
        <Pagination pagination={data?.pagination} onPageChange={fetchData} />
      )}
    </>
  );
};

export default Requests;

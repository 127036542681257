import React, { useState } from "react";
import SearchSVG from "../assets/images/search.svg";

const Search = ({ search, nameSearch, setNameSearch }) => {
  return (
    <form onSubmit={search}>
      <div className="join border border-gray-300 max-w-fit px-0">
        <div>
          <div>
            <input
              className="join-item input border-0"
              placeholder="Search"
              value={nameSearch}
              onChange={(e) => setNameSearch(e.target.value)}
            />
          </div>
        </div>
        <div className="indicator">
          <button className="btn join-item border-0">
            <img src={SearchSVG} alt="Search" className="h-6" />
          </button>
        </div>
      </div>
    </form>
  );
};

export default Search;

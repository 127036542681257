import React, { useState } from "react";
import { Link } from "react-router-dom";
import { date, time, isVideo } from "../helpers";
import { useParams } from "react-router-dom";
import LinkIcon from "../assets/images/open-in-new-window.svg";

const MessageItem = ({
  heading = false,
  loading = false,
  item = false,
  community_slug,
  user = {},
}) => {
  const { member_id, receiver_id } = useParams();

  if (heading === true) {
    return (
      <div className="grid grid-cols-10 font-semibold mb-7 gap-6 px-2">
        <div className="col-span-7">{user?.name}'s Messages</div>
        <div className="col-span-3 text-center">Action</div>
      </div>
    );
  } else if (loading) {
    return (
      <div className="grid grid-cols-10 py-3 gap-6 px-2 rounded-lg animate-pulse">
        <div className="col-span-7">
          <div className="h-4 w-52 bg-gray-400 rounded-md"></div>
        </div>
        <div className="col-span-3 flex">
          <div className="h-4 w-32 bg-gray-400 rounded-md mx-auto"></div>
          <div className="h-4 w-32 bg-gray-400 rounded-md mx-auto"></div>
        </div>
      </div>
    );
  } else if (item) {
    return (
      <div
        key={item.id}
        className={`chat mb-4 ${
          member_id == item.sender_id ? "chat-start" : "chat-end"
        }`}
      >
        <div className="chat-header">{item.sender_name}</div>
        <div className="chat-bubble bg-gray-300 my-1">
          {item.media.length > 0 &&
            item.media.map((media) => {
              return isVideo(media.url) ? (
                // <video
                //   preload="metadata"
                //   key={media.id}
                //   src={media.url}
                //   className="max-w-sm rounded-xl mt-2"
                //   controls
                // >
                //   Your browser does not support the video tag.
                // </video>
                <a
                  href={media.url}
                  target="_blank"
                  className="w-36 h-28 flex justify-center rounded-xl items-center bg-gray-500 text-white mb-2"
                >
                  Video <img src={LinkIcon} alt="Search" className="h-6" />
                </a>
              ) : (
                <img
                  src={media.url}
                  key={media.id}
                  className="max-w-sm rounded-xl mb-2"
                  alt="media"
                />
              );
            })}
          {item.chat}
        </div>
        <div className="chat-footer opacity-50">
          {date(item.created_at)}
          {/* {time(item.created_at)} */}
        </div>
      </div>
    );
  }
};

export default MessageItem;

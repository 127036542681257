import API from "./api";

export const list = async ({ community_slug, page = 1 }) => {
  let APIURL = `${community_slug}/post?page=${page}`;
  return await API.get(APIURL);
};

export const deletedList = async ({ community_slug, page }) => {
  let APIURL = `${community_slug}/post?deleted=1&page=${page}`;
  return await API.get(APIURL);
};

export const destory = async ({ community_slug, id }) => {
  return await API.delete(`${community_slug}/post/${id}`);
};

export const comments = async ({ community_slug, post_id }) => {
  return await API.get(`${community_slug}/comments/${post_id}`);
};

export const destoryComment = async ({ community_slug, id }) => {
  return await API.delete(`${community_slug}/comment/${id}`);
};

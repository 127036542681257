import API from "./api";

export const signup = async (data) => {
  return await API.post("register", data);
};

export const loginAPI = async (credentials) => {
  return await API.post("login", credentials);
};

export const forgotPassword = async (data) => {
  return await API.post("forget-password", data);
};

export const resetPassword = async (data) => {
  return await API.post("reset-password", data);
};

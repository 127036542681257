import axios from "axios";
import toast from "react-hot-toast";
import { getFromLocalStorage, clearLocalStorage } from "../utils/localStorage";

const Toast = toast;
const ToastOptions = {
  duration: 5000,
  position: "top-right",
  id: new Date(),
};

const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  transformRequest: [
    function (data) {
    //   Toast.loading("Please wait...", { ...ToastOptions, duration: 100 });
      return JSON.stringify(data);
    },
  ],
});

API.interceptors.request.use(
  (config) => {
    const token = getFromLocalStorage("authToken");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    const data = response?.data;
    if (data?.data?.status === true || data?.success === true) {
      sucessTost(data);
    } else {
      errorTost(data);
    }
    return response.data;
  },
  (error) => {
    if(error.response.status == 401){
        clearLocalStorage();
    }
    const data = error.response.data;
    errorTost(data);
    return Promise.reject(data);
  }
);

const sucessTost = (data) => {
  setTimeout(() => {
    if (data?.message && typeof data?.message === "string") {
      toast.success(data.message, ToastOptions);
    } else if (data?.status && typeof data?.status === "string") {
      toast.success(data.status + ` - Status code: ${data.code}`, ToastOptions);
    }
  }, 500);
};

const errorTost = (data) => {
  setTimeout(() => {
    if (data?.message && typeof data?.message === "string") {
      toast.error(data.message, ToastOptions);
    } else if (data?.status && typeof data?.status === "string") {
      toast.error(data.status + ` - Status code: ${data.code}`, ToastOptions);
    }
  }, 500);
};

export default API;

import React, { useEffect, useState } from "react";
import { list } from "../api/communities";
import CommunityItem from "../components/CommunityItem";
import NoItems from "../components/NoItems";
import Signup from "../components/Signup";
import Search from "../components/Search";
import Pagination from "../components/Pagination";

const Communities = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [nameSearch, setNameSearch] = useState("");

  const fetchData = async (page = 1) => {
    if (page == 1) {
      setIsLoading(true);
    }
    try {
      const response = await list({
        name: nameSearch,
        page: page,
      });
      setData(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const search = async (e) => {
    e.preventDefault();
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="flex flex-col lg:flex-row justify-between my-6 mx-6 lg:mx-0 gap-2 lg:gap-0">
        <div className="stats border border-black">
          {data?.apps.length > 0 &&
            data.apps.map((app) => (
              <div className="stat min-w-40 py-1 px-3">
                <div className="stat-title">{app.name}</div>
                <div className="stat-value">{app.members_count}</div>
              </div>
            ))}
        </div>
        <div className="flex gap-5">
          {/* Serch */}
          <Search
            search={search}
            nameSearch={nameSearch}
            setNameSearch={setNameSearch}
          />
          <button
            className="btn btn-primary"
            onClick={() => document.getElementById("add_community").showModal()}
          >
            Add community
          </button>
        </div>
      </div>

      {/* Community list */}
      <div className="my-20 lg:my-10 border border-gray-300 p-3 rounded-lg striped">
        <CommunityItem heading={true} />
        {isLoading === true && (
          <>
            <CommunityItem loading={true} />
            <CommunityItem loading={true} />
          </>
        )}
        {isLoading === false &&
          (typeof data.communities?.data === "undefined" ||
            data.communities?.data.length === 0) && <NoItems />}
        {isLoading === false &&
          typeof data.communities?.data !== "undefined" &&
          data.communities?.data.map((item) => (
            <CommunityItem key={item.id} item={item} fetchData={fetchData} />
          ))}

        {isLoading === false &&
          typeof data?.communities?.data !== "undefined" && (
            <Pagination
              pagination={data?.communities?.pagination}
              onPageChange={fetchData}
            />
          )}
      </div>

      {/* Add community popup */}
      <dialog id="add_community" className="modal">
        <div className="modal-box">
          <form method="dialog">
            {/* if there is a button in form, it will close the modal */}
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
              ✕
            </button>
          </form>
          <h3 className="font-bold text-lg mb-10">Add new community</h3>
          <Signup />
        </div>
      </dialog>
    </>
  );
};

export default Communities;

import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { getFromLocalStorage } from "../../utils/localStorage";
import ManageAccount from "../../components/ManageAccount";
import { getCommunityMembersCount } from "../../api/communities";

const ManageAccountPage = () => {
  const [data, setData] = useState(null);
  const [community, setCommunity] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [initialValues, setInitialValues] = useState(null);
  const { user } = useContext(AuthContext);
  const { community_slug } = useParams();

  useEffect(() => {
    const communityData = getFromLocalStorage("community");
    setCommunity(communityData);
  }, []);

  const fetchData = async (page = 1) => {
    setIsLoading(true);
    try {
      const response = await getCommunityMembersCount(community_slug);
      setData(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    if (user.role == "admin" && community) {
      setInitialValues({
        name: community.name,
        email: community.email,
        url: community.slug,
        total_member: community.total_member,
      });
    } else {
      setInitialValues({
        name: user.name,
        email: user.email,
        url: user.slug,
        total_member: user.total_member,
      });
    }
  }, [community]);

  return (
    <>
      {initialValues && (
        <ManageAccount initialValues={initialValues} data={data} />
      )}
    </>
  );
};

export default ManageAccountPage;

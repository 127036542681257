import React, { useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const Login = () => {
  const { login, isLoading } = useContext(AuthContext);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const initialValues = {
    email: "",
    password: "",
  };

  const handleSubmit = async (values) => {
    try {
      await login(values);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <h2 className="text-3xl text-center mb-10">Welcome back!</h2>
            <div className="mb-4">
              <label
                htmlFor="username"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Email
              </label>
              <Field
                type="email"
                name="email"
                className="input input-bordered"
              />
              <ErrorMessage name="email" className="error" component="div" />
            </div>
            <div className="mb-6">
              <label
                htmlFor="password"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Password
              </label>
              <Field
                type="password"
                name="password"
                id="password"
                autoComplete="false"
                className="input input-bordered"
              />
              <ErrorMessage name="password" className="error" component="div" />
            </div>
            <div className="flex justify-between items-center">
              <Link to="/forgot-password">Forgot password?</Link>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitting} // Disable button while loading
              >
                {isSubmitting ? "Loading..." : "Login"}
              </button>
            </div>

            <div className="text-center mt-8">
              <Link to="/signup">Create new community</Link>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Login;

import React from "react";

const Pagination = ({ pagination, onPageChange }) => {
  const getPageNumbers = () => {
    const maxPagesToShow = 5;
    let startPage, endPage;

    if (pagination.last_page <= maxPagesToShow) {
      startPage = 1;
      endPage = pagination.last_page;
    } else {
      const maxPagesBeforeCurrentPage = Math.floor(maxPagesToShow / 2);
      const maxPagesAfterCurrentPage = Math.ceil(maxPagesToShow / 2) - 1;

      if (pagination.current_page <= maxPagesBeforeCurrentPage) {
        startPage = 1;
        endPage = maxPagesToShow;
      } else if (
        pagination.current_page + maxPagesAfterCurrentPage >=
        pagination.last_page
      ) {
        startPage = pagination.last_page - maxPagesToShow + 1;
        endPage = pagination.last_page;
      } else {
        startPage = pagination.current_page - maxPagesBeforeCurrentPage;
        endPage = pagination.current_page + maxPagesAfterCurrentPage;
      }
    }

    return [...Array(endPage + 1 - startPage).keys()].map((i) => startPage + i);
  };

  const pages = getPageNumbers();

  return (
    <div className="flex justify-between my-4">
      <div>
        From {pagination.from} To {pagination.to} of {pagination.total}
      </div>
      <div className="join">
        <button
          className={
            pagination.current_page == 1
              ? "join-item btn btn-active pointer-events-none cursor-none border-gray-300 text-gray-300"
              : "join-item btn"
          }
          onClick={() => onPageChange(pagination.current_page - 1)}
        >
          Previous
        </button>

        {pages.map((page) => (
          <button
            key={page}
            onClick={() => onPageChange(page)}
            className={
              page === pagination.current_page
                ? "join-item btn btn-active pointer-events-none cursor-none border-gray-300 text-gray-300"
                : "join-item btn"
            }
          >
            {page}
          </button>
        ))}
        <button
          className={
            pagination.current_page === pagination.last_page
              ? "join-item btn btn-active pointer-events-none cursor-none border-gray-300 text-gray-300"
              : "join-item btn"
          }
          onClick={() => onPageChange(pagination.current_page + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Pagination;

import API from "./api";

export const list = async ({ name, page }) => {
  let APIURL = "communities";
  if (name) {
    APIURL += `?name=${name}&page=${page}`;
  } else {
    APIURL += `?page=${page}`;
  }
  return await API.get(APIURL);
};

export const updateStatus = async (data) => {
  return await API.post("community-update-status", data);
};

export const destory = async (id) => {
  return await API.post(`delete-community/${id}`);
};

export const destoryUSer = async (id, community_slug) => {
    return await API.post(`${community_slug}/delete-user/${id}`);
  };

export const members = async ({ slug, name, page = 1 }) => {
  let APIURL = `${slug}/members`;
  if (name) {
    APIURL += `?community=${name}&page=${page}`;
  } else {
    APIURL += `?page=${page}`;
  }
  return await API.get(APIURL);
};

export const getCommunityMembersCount = async (slug) => {
  return await API.get(`${slug}/community-members-count`);
};

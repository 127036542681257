import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { list } from "../../api/posts";
import PostItem from "../../components/PostItem";
import NoItems from "../../components/NoItems";
import Pagination from "../../components/Pagination";

const Posts = () => {
  const { community_slug } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async (page = 1) => {
    if(page == 1){
        setIsLoading(true);
    }
    try {
      const response = await list({
        community_slug: community_slug,
        page: page,
      });
      setData(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {/* Post List */}
      <h2 className="text-xl font-semibold mb-5">Posts</h2>
      <div className="flex flex-col gap-8">
        {isLoading === true && typeof data?.data !== "undefined" && (
          <>
            <PostItem loading={true} />
            <PostItem loading={true} />
          </>
        )}
        {isLoading === false &&
          (typeof data?.data === "undefined" || data?.data.length === 0) && (
            <NoItems />
          )}
        {isLoading === false &&
          typeof data?.data !== "undefined" &&
          data?.data.map((item) => (
            <PostItem
              key={item.id}
              item={item}
              fetchData={fetchData}
              community_slug={community_slug}
              page={data?.pagination?.current_page}
            />
          ))}
      </div>
      {isLoading === false && typeof data?.data !== "undefined" && (
        <Pagination pagination={data?.pagination} onPageChange={fetchData} />
      )}
    </>
  );
};

export default Posts;

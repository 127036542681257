import API from "./api";

export const index = async ({ community_slug, page = 1 }) => {
  let APIURL = `${community_slug}/channels?page=${page}`;
  return await API.get(APIURL);
};

export const destory = async ({ community_slug, id }) => {
  let APIURL = `${community_slug}/channels/${id}`;
  return await API.delete(APIURL);
};

export const store = async ({ community_slug, data }) => {
  let APIURL = `${community_slug}/channels`;
  return await API.post(APIURL, data);
};

export const changeManager = async ({ community_slug, id, data }) => {
  let APIURL = `${community_slug}/channels/${id}`;
  return await API.patch(APIURL, data);
};

export const userSelection = async ({ community_slug }) => {
  let APIURL = `${community_slug}/user-selection`;
  return await API.get(APIURL);
};

export const channelUsers = async ({ community_slug, id }) => {
  let APIURL = `${community_slug}/channels-users/${id}`;
  return await API.get(APIURL);
};

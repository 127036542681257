import React, { useState, useContext } from "react";
import { resetPassword } from "../api/auth";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, strConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object({
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .max(50, "Password must be at most 50 characters")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/[0-9]/, "Password must contain at least one number")
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        "Password must contain at least one special character"
      )
      .required("Password is required"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const initialValues = {
    password: "",
    confirm_password: "",
    token: token,
  };

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      const response = await resetPassword(values);
      if (response?.data?.status === true) {
        navigate("/login");
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <h2 className="text-3xl text-center mb-10">Reset Password</h2>
            <div>Please enter your desired password.</div>
            <div className="mt-8 mb-4">
              <label
                htmlFor="password"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Password
              </label>
              <Field
                type="password"
                id="password"
                name="password"
                autoComplete="false"
                className="input input-bordered"
              />
              <ErrorMessage name="password" className="error" component="div" />
            </div>
            <div className="mt-4 mb-8">
              <label
                htmlFor="confirm_password"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Confirm Password
              </label>
              <Field
                type="password"
                id="confirm_password"
                name="confirm_password"
                autoComplete="false"
                className="input input-bordered"
              />
              <ErrorMessage
                name="confirm_password"
                className="error"
                component="div"
              />
            </div>

            {/* {errorMessage && <p className="text-red-500 text-sm mb-4">{errorMessage}</p>} */}
            <div className="flex justify-between items-center">
              <Link to="/login">Login</Link>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isLoading} // Disable button while loading
              >
                {isLoading ? "Pelase wait..." : "Submit"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ResetPassword;

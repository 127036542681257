import React, { useContext } from "react";
import { NavLink, useParams } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

const Topbar = () => {
  const { user } = useContext(AuthContext);
  const { community_slug } = useParams();

  const handleClick = () => {
    const checkbox = document.getElementById("my-drawer-2");
    checkbox.checked = !checkbox.checked;
  };

  return (
    <div className="sidebar" onClick={handleClick}>
      <NavLink to={`/${community_slug}/members`}>Members</NavLink>
      <NavLink to={`/${community_slug}/requests`}>Requests</NavLink>
      <NavLink to={`/${community_slug}/channels`}>Channels</NavLink>
      <NavLink to={`/${community_slug}/posts`}>Posts</NavLink>
      <NavLink to={`/${community_slug}/deleted`}>Deleted</NavLink>
      <div className="text-center text-gray-400 pb-3 border-b border-b-gray-300">
        <div className="text-xl">Community Code</div>
        <div className="text-2xl">{user.orrrg_code}</div>
      </div>
      <div className="text-center text-gray-400">
        <div className="text-xl">Contact Us:</div>
        <div className="text-2xl">hi@orrrg.com</div>
      </div>
    </div>
  );
};

export default Topbar;

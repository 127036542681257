import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import Topbar from "./Topbar";

const Layout = () => {
  const { authToken } = useContext(AuthContext);
  if (!authToken) {
    return <Navigate to="/login" />;
  }
  return (
    <div className="flex flex-col justify-between min-h-screen">
      <Topbar />
      <div className="flex-grow">
        <div className="container">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;

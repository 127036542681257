import API from "./api";

export const index = async ({ community_slug, page = 1 }) => {
  let APIURL = `${community_slug}/request?page=${page}`;
  return await API.get(APIURL);
};

export const updateStatus = async ({ community_slug, id, status }) => {
  let APIURL = `${community_slug}/request/${id}`;
  return await API.put(APIURL, { status });
};

import React, { createContext, useState, useContext } from "react";
import toast, { Toaster } from "react-hot-toast";

export const AlertContext = createContext({
  showSuccessAlert: (message) => {},
  showErrorAlert: (message) => {},
  showInfoAlert: (message) => {},
  showWarningAlert: (message) => {},
  isLoading: false,
  setIsLoading: (loading) => {},
});

const AlertProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const showSuccessAlert = (message) => toast.success(message);
  const showErrorAlert = (message) => toast.error(message);
  const showInfoAlert = (message) => toast.info(message);
  const showWarningAlert = (message) => toast.warning(message);

  return (
    <AlertContext.Provider
      value={{
        showSuccessAlert,
        showErrorAlert,
        showInfoAlert,
        showWarningAlert,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
      <Toaster />
    </AlertContext.Provider>
  );
};

export default AlertProvider;

export const getFromLocalStorage = (key) => {
  try {
    const item = localStorage.getItem(key);
    return item !== "undefined" ? JSON.parse(item) : null;
  } catch (error) {
    console.error("Error retrieving data from localStorage:", error);
    return null;
  }
};

export const setToLocalStorage = async (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error("Error saving data to localStorage:", error);
  }
};

export const clearLocalStorage = (key, value) => {
  try {
    localStorage.clear();
  } catch (error) {
    console.error("Error clearing data of localStorage:", error);
  }
};
